import React, { useState,useRef } from 'react';
import { graphql } from "gatsby"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1, Slogan } from '../components/styles'
import Layout from "../components/layout"
import Group from "../components/group"
import ContBlock from "../components/contBlock"

dayjs.extend(utc)
dayjs.extend(timezone)

export const pageQuery = graphql`
 query {
    allStrapiAboutUs{
        edges {
         node {
           seo {
            metaTitle
            metaDescription
            shareImage {
                url
                localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
           }
           slogan
           values
           title {
            title
            description
           }
           story {
            title
            description
           }
           stand {
            title
            description
           }
           studios {
            title
            sub_title
            link {
                url
            }
            image {
                localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
           }
           beliefs {
               title
               description
           }
           slogan2 {
                description
                image {
                    localFile {
                        childImageSharp {
                          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
           }
           slogan3
           designers {
            location
            people {
                name
                title
                email
                social_name
                social_href
                image {
                    localFile {
                        childImageSharp {
                          gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
              }
           }
         }
       }
    }
 }`
export default function IndexPage({location,data}) {
    const node = data.allStrapiAboutUs.edges[0].node
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] text-xl about lg:pb-[120px]">
                <h1 data-aos="fade-up" data-aos-duration="600" className="text-[200px] leading-none pb-[95px] pt-[260px] lg:pt-[165px] xl:text-[150px] lg:text-[72px]">{node.title.title}</h1>
                <figure data-aos="fade-down" data-aos-duration="600"><GatsbyImage className="w-full" image={getImage(node.seo.shareImage.localFile)} alt="banner"/></figure>
                <Slogan data-aos="fade-up" data-aos-offset="300" data-aos-duration="600" className="pt-[200px] com-indent lg:pt-[120px]">{node.title.description}</Slogan>
                <div className="mb-[200px] lg:mb-[120px]"><ContBlock title={node.story.title} cont={node.story.description}/></div>
                <div className="mb-[200px] lg:mb-[120px]"><ContBlock title={node.stand.title} cont={node.stand.description}/></div>
                <div className="mb-[200px] lg:mb-[120px]"><ContBlock lg={true} title="Studios" cont={
                    <div className="grid grid-cols-3 gap-x-8 gap-y-14 md:grid-cols-1">
                        {
                            node.studios.map((n,i)=>{
                                var d = new Date();
                                switch(n.sub_title){
                                    case "A":
                                    n.sub_title=dayjs().format('HH:mm')
                                    break;
                                    case "B":
                                    n.sub_title=dayjs().format('HH:mm')
                                    break;
                                    case "C":
                                    n.sub_title=dayjs().tz("Europe/Oslo").format('HH:mm')
                                    break;
                                    default:
                                    n.sub_title=dayjs().format('HH:mm')
                                    break;
                                }
                                n.sub_title = `${n.title} · ${n.sub_title}`
                                return <Group imgH='!pt-[57.6%]' lg={true} key={i} node={n} />
                            })
                        }
                    </div>
                }/></div>
                <Slogan className="com-indent">{node.slogan}</Slogan>
                <ContBlock lg={true} title="Beliefs" cont={
                    <div className="grid grid-cols-3 gap-y-14 gap-x-8 md:grid-cols-1">
                    {
                        node.beliefs.map((n,i)=>{
                            return (
                            <div key={i}>
                                <h3 className="pb-2 font-bold">{n.title}</h3>
                                <p>{n.description}</p>
                            </div>)
                        })
                    }
                    </div>
                }/>
                <div className="grid grid-cols-12 gap-8 pt-[144px] pb-[200px] lg:grid-cols-1 lg:pb-[120px]">
                    <figure className="col-span-9 lg:col-span-1"><GatsbyImage className="w-full" image={getImage(node.slogan2.image.localFile)} alt="banner"/></figure>
                    <p className="col-span-3 lg:col-span-1">{node.slogan2.description}</p>
                </div>
                <div className="mb-[200px] lg:mb-[120px]"><ContBlock title="Our values" cont={node.values}/></div>
                <Slogan className="com-indent">{node.slogan3}</Slogan>
                <h2 className="text-[40px] pb-14 leading-none">Leadership</h2>
                <div className="grid grid-cols-4 gap-8 lg:grid-cols-2 sm:grid-cols-1">
                    {
                        node.designers.map((n,i)=>{
                            return (
                            <div data-aos="fade-up" dataAosDuration={600+i*400} key={i}>
                                <figure><GatsbyImage className="w-full" image={getImage(n.people.image.localFile)} alt="banner"/></figure>
                                <h4 className="text-[32px] leading-snug py-4">{n.people.name}</h4>
                                <p className="pb-4">{n.people.title}</p>
                                <p className="pb-[22px]"><A variant="border-gray" href={`mailto:${n.people.email}`}>{n.people.email}</A></p>
                            </div>)
                        })
                    }
                </div>
            </div>
        </Layout>
    )
}

